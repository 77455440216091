const gdnkyRouter = {
  route: null,
  name: null,
  title: '广东农科院血检',
  type: 'folder', // 类型: folder, tab, view
  icon: 'iconfont icon-jiemiansheji',
  isElementIcon: false,
  filePath: 'view/gdnky/', // 文件路径
  order: null,
  inNav: true,
  children: [
    {
      title: '二维码管理',
      type: 'view',
      name: 'QrcodeList',
      route: '/gdnky/list',
      filePath: 'view/gdnky/list.vue',
      inNav: true,
      icon: 'iconfont icon-jiemiansheji',
      isElementIcon: false,
    },
  ],
}

export default gdnkyRouter
