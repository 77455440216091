const auburnRouter = {
  route: null,
  name: null,
  title: '奥本血检',
  type: 'folder', // 类型: folder, tab, view
  icon: 'iconfont icon-jiemiansheji',
  isElementIcon: false,
  filePath: 'view/auburn/', // 文件路径
  order: null,
  inNav: true,
  children: [
    // 芯片号管理
    {
      title: '芯片号管理',
      type: 'view',
      name: Symbol('center'),
      route: '/auburn/chiplist/index',
      filePath: 'view/auburn/chiplist/index.vue',
      inNav: true,
      icon: 'iconfont icon-add',
      permission: ['芯片号管理'],
    },
    // 堪萨斯芯片爬虫
    {
      title: '爬虫管理',
      type: 'view',
      name: Symbol('center'),
      route: '/auburn/ab-spider/index',
      filePath: 'view/auburn/ab-spider/index.vue',
      inNav: true,
      icon: 'iconfont icon-add',
      permission: ['爬虫管理'],
    },
  ],
}

export default auburnRouter
